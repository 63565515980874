@import '../../../../../../../../../../../tools/themes/color-palette.scss';

.shopping-cart-box {
  .content-box {
    .shopping-cart-header {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .scroll-box {
      background: $secondaryColorGray;
      width: inherit;

      .shopping-cart-body .items-box {
        display: flex;
        flex-direction: column;
        gap: 1px;
        border-radius: 4px;
        overflow: hidden;

        .item-box,
        .product-box {
          background: $primaryColorWhite;
        }
      }
    }
  }

  &.mobile {
    width: 100vw;
    height: 100%;
    background: $secondaryColorGray;

    .scroll-box {
      eshop-alerts {
        width: 100%;
      }
    }
  }

  &.desktop {
    display: flex;
    justify-content: center;

    .content-box {
      width: 326px; // 6px navíc je kvůli scrollbaru
      position: relative; //slouží k ukotvení alertů
      border: 1px solid $primaryColorGrayBorder;
      border-radius: 0 0 4px 4px;
      box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.25);

      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        background: $scrollbarTrack;
      }
      ::-webkit-scrollbar-thumb {
        background: $scrollbarThumb;
        border-radius: 16px;
        overflow: hidden;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: $scrollbarThumbHover;
      }

      .scroll-box {
        overflow-y: auto;
        padding: 0 4px;
        width: 100%;

        eshop-alerts {
          width: 310px;
        }
      }
    }

    &.delivery-config-page {
      .scroll-box {
        height: calc(
          100vh - 118px - 90px
            //max výška stránky mínus výška globální hlavičky a hlavičky košíku v konfigurátoru doručení
        ); //max výška stránky mínus výška hlavičky
      }
    }
    &:not(.delivery-config-page) {
      .scroll-box {
        height: calc(
          100vh - 118px - 60px
            //max výška stránky mínus výška globální hlavičky a hlavičky košíku
        ); //max výška stránky mínus výška hlavičky
      }
    }

    &.desktop-home-page {
      //odstraní rámeček a stín z prázdného košíku (který je na home-page skrytý)
      &.empty {
        .content-box {
          border: none;
          box-shadow: none;
        }

        // po kliknutí na tlačítko vysypat košík jsem pořád na hoveru a musím tedy resetovat výšku scroll-boxu
        .content-box:hover {
          .scroll-box {
            height: 0;
          }
        }
      }

      .content-box {
        //ukotvení košíku na stránce pod hlavičkou
        position: absolute;
        right: 32px;
        max-height: calc(
          100vh - 118px
        ); //max výška stránky mínus výška globální hlavičky
        z-index: 5000; // musím překrýt csrolltop na home-page

        .scroll-box {
          height: fit-content;

          //skryje vše krom hlavičky
          .shopping-cart-body {
            visibility: hidden;
            height: 0;
          }
        }
      }

      //na hover odkryje celý obsah košíku
      .content-box:hover {
        .scroll-box {
          height: calc(
            100vh - 118px - 60px
          ); //max výška stránky mínus výška globální hlavičky a hlavičky košíku

          .shopping-cart-body {
            visibility: visible;
          }
        }
      }
    }
  }

  &.desktop.empty {
    .content-box .scroll-box eshop-alerts {
      max-height: 0; //zamezí scrollování
    }

    .scroll-box {
      height: calc(
        100vh - 118px
      ); //max výška stránky mínus výška globální hlavičky
    }
  }

  &.mobile.empty {
    .content-box,
    .scroll-box {
      height: inherit; //umožní vertikální roztažení

      eshop-alerts {
        max-height: 0; //zamezí scrollování
      }
    }
  }
}
