eshop-login-button {
  @import '../../../../../../../../../../../tools/themes/color-palette.scss';

  .icon-btn {
    .login-btn-box {
      display: flex;
      align-items: center;
      align-content: center;

      height: 100%;
      width: 100%;

      .btn-text-box {
        color: $textColorBlack;
        font-weight: 400;
      }

      &.mobile {
        flex-direction: column;
        justify-content: center;

        &.logged-in .btn-text-box {
          color: $textColorWhite;
          font-weight: 700;
        }
      }

      &.desktop {
        flex-direction: row;
        justify-content: space-evenly;
        gap: 8px;

        &.logged-in {
          color: $primaryColorGreen;

          .btn-text-box {
            color: $primaryColorGreen;
            font-weight: 700;
          }
        }
      }
    }
  }

  .button-menu {
    margin-top: 15px;
  }
}
