eshop-search-box {
  @import '../../../../../../../../../../../tools/themes/color-palette.scss';

  .search-box-box {
    display: flex;
    flex-direction: row;
    padding: 0;
    height: 100%;
    width: 100%;

    .p-input-icon-right {
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      input {
        border: 1px solid $primaryColorGrayIcon;
        border-radius: 4px;
        padding: 4px 8px;
        gap: 8px;
        width: 100%;
        height: 100%;
        font-size: 14px;
      }
    }
  }

  .search-result-overlay {
    position: fixed;
    z-index: 1000;
    background: $primaryColorWhite;

    &.mobile {
      top: 106px;
      height: 100%;
      width: 100%;
      left: 0;

      .mobile-header {
        display: flex;
        justify-content: flex-end;
        padding-top: 4px;
        padding-right: 4px;

        .p-button {
          width: 24px;
          height: 24px;
          background: $secondaryColorGray;

          &:hover {
            background: $secondaryColorGrayHover;
          }

          &:active {
            background: $secondaryColorGrayHighlight;
          }

          .p-button-icon {
            color: $textColorBlack;
            font-size: 12px;
          }
        }
      }

      .scroll-box {
        // 106px je výška mobile hlavičky a 28px je výška "mobile-heade"
        height: calc(100% - 106px - 28px);
        width: 100%;

        .result-section {
          padding-bottom: 32px;
        }
      }
    }

    &.desktop {
      width: 90%;
      left: 5%;
      top: 64px;

      border-radius: 0 0 15px 15px;
      -webkit-box-shadow: -7px 22px 26px -10px rgba(0, 0, 0, 0.6);
      -moz-box-shadow: -7px 22px 26px -10px rgba(0, 0, 0, 0.6);
      box-shadow: -7px 22px 26px -10px rgba(0, 0, 0, 0.6);
      overflow: hidden;

      .scroll-box {
        min-height: 150px;
        max-height: calc(100vh - 126px);
        padding: 16px 0;
        gap: 16px;

        // nastavení stylování scrollbaru
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background: $scrollbarTrack;
        }
        &::-webkit-scrollbar-thumb {
          background: $scrollbarThumb;
          border-radius: 16px;
          overflow: hidden;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: $scrollbarThumbHover;
        }

        .no-data {
          height: 118px;
        }
      }
    }

    .scroll-box {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        height: 100%;
        width: 100%;
        padding: 16px;

        color: $textColorBlack;
        font-size: 24px;
        font-weight: 700;
      }

      .show-all-button {
        display: flex;
        justify-content: center;
        margin: 10px;
      }

      .desktop-divider {
        width: 100%;
        border-bottom: 1px solid $primaryColorGray;
      }

      .result-section {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 16px;

        .section-label {
          font-weight: 700;
          font-size: 18px;
          color: $primaryColorGreen;
        }
      }
    }
  }
}
