eshop-categories-desktop {
  @import '../../../../../../../../../../../tools/themes/color-palette.scss';

  .grouping-box {
    width: 100%;
    height: 100%;

    display: flex;
    cursor: default;

    .btn-box {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;

      &.red-highlighted {
        background: $textColorRed;
      }

      &:hover {
        cursor: pointer;
      }

      &:focus-visible {
        outline: none;
      }

      .btn-label-box {
        margin-bottom: 8px;
        color: $primaryColorWhite;
        font-size: 15px;
        font-weight: 400;
      }
    }

    .sub-categories-box {
      position: fixed;
      top: 118px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 64px);

      overflow: hidden;
      border-radius: 0 0 16px 16px;
      border: 1px solid $primaryColorGray;
      border-top: none;
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);

      .sub-categories-scroll-box {
        overflow-y: auto;
        height: fit-content;
        max-height: calc(100vh - 126px);

        // nastavení stylování scrollbaru
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          background: $scrollbarTrack;
        }
        &::-webkit-scrollbar-thumb {
          background: $scrollbarThumb;
          border-radius: 16px;
          overflow: hidden;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: $scrollbarThumbHover;
        }

        eshop-categories-desktop-cell {
          width: 25%;

          border: 1px solid $primaryColorGray;
          border-top: none;
          border-right: none;
        }

        eshop-categories-desktop-cell:nth-child(4n + 1) {
          border-left: none;
        }

        eshop-categories-desktop-cell:nth-last-child(-n + 4) {
          border-bottom: none;
        }

        .empty-cell {
          border-left: 1px solid $primaryColorGray;
        }

        .empty-cell ~ .empty-cell {
          border-left: none;
        }
      }
    }
  }

  .p-tabmenu {
    &.triggered .p-tabmenu-nav .p-tabmenuitem:hover {
      background-color: $primaryColorWhite !important;

      .btn-box {
        .btn-label-box {
          color: $textColorBlack;
          text-decoration: underline;
        }

        &.red-highlighted {
          .btn-label-box {
            color: $primaryColorWhite;
          }
        }
      }
    }

    .p-tabmenu-nav {
      border: none;
      border-radius: 0;
      padding: 8px 33px 0;
      gap: 8px;
      width: 100%;
      height: 56px;
      background: $primaryColorGreen;

      .p-tabmenuitem {
        width: 100%;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
      }
    }
  }
}
