@import '../../../../../../../../../../../tools/themes/color-palette.scss';

.header-box {
  position: sticky;
  z-index: 1000;
  top: 0;

  .header-top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $primaryColorWhite;
    padding: 0 32px 0 32px;
    gap: 12px;

    width: 100%;
    height: 62px;

    .eshop-logo {
      width: 128px;
      height: 46px;

      &:hover {
        cursor: pointer;
      }
    }

    .search-box-box {
      display: flex;
      justify-content: center;
      align-items: stretch;

      width: 400px;
      height: 32px;
      padding: 0;

      eshop-search-box {
        width: 100%;
      }
    }

    .button-box {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
  }
}
