@import '../../../../../../../../../../../tools/themes/color-palette.scss';

:host {
  width: 100%;

  .attracting-section {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .first-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 12px;

      &.has-name {
        justify-content: space-between;
      }
      &:not(has-name) {
        justify-content: flex-end;
      }

      .as-name {
        width: fit-content;
        text-decoration: none;
        color: $textColorBlack;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}
