eshop-products-carousel {
  @import '../../../../../../../../../../../tools/themes/color-palette.scss';

  .p-carousel {
    overflow: hidden;

    .p-carousel-item {
      text-align: -webkit-center;
      padding: 0 4px;
    }

    .p-carousel-content {
      .p-carousel-prev:disabled,
      .p-carousel-next:disabled {
        visibility: hidden;
      }
    }

    &.mobile {
      .p-carousel-content {
        .p-carousel-prev {
          margin-left: -28px;
          left: 28px;
          z-index: 1;
        }

        .p-carousel-next {
          margin-right: -28px;
          right: 28px;
          z-index: 1;
        }
      }
    }

    &.desktop {
      .p-carousel-content {
        .p-carousel-prev,
        .p-carousel-next {
          width: 32px;
          height: 32px;
          color: $textColorBlack;
          background: $secondaryColorGray;

          &:hover {
            background: $secondaryColorGrayHover;
          }

          &:active {
            background: $secondaryColorGrayHighlight;
          }
        }

        .p-carousel-prev {
          margin-right: 4px;
        }

        .p-carousel-next {
          margin-left: 8px;
        }
      }
    }
  }
}
