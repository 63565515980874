$textColorBlack: #333333;
$textColorWhite: #ffffff;
$textColorGray: #828282;
$textColorRed: #d20000;

$primaryColorGreen: #80cc13;
$primaryColorGreenHover: #73b711;
$primaryColorGreenHighlight: #66a30f;
$primaryColorGreenFocus: #a6db59;
$primaryColorGreenBorder: #6ab309;
$primaryColorGreenFocusRing: #aeea79;

$secondaryColorGreen: #a9ea6b;
$secondaryColorGreenHover: #98d260;
$secondaryColorGreenHighlight: #87bb55;
$secondaryColorGreenFocus: #c2f097;
$secondaryColorGreenBorder: #93d161;

$tertiaryColorGreen: #f1f1f1;

$primaryColorGray: #e0e0e0;
$primaryColorGrayHover: #c9c9c9;
$primaryColorGrayHighlight: #b3b3b3;
$primaryColorGrayBorder: #bdbdbd;
$primaryColorGrayIcon: #c4c4c4;

$secondaryColorGray: #f2f2f2;
$secondaryColorGrayHover: #d9d9d9;
$secondaryColorGrayHighlight: #c1c1c1;

$primaryColorRed: #ffc2c2;
$primaryColorRedHover: #e5aeae;
$primaryColorRedHighlight: #cc9b9b;

$secondaryColorRed: #ffe6e6;
$secondaryColorRedHover: #e5cfcf;
$secondaryColorRedHighlight: #ccb8b8;

$primaryColorWhite: #ffffff;

$disabledColor: #f2f2f2;
$primaryBackgroundColor: #f5f8ea;
$desktopBackgroundColor: #e0e0e0;

$scrollbarTrack: #e9e9e9;
$scrollbarThumb: #9c9c9c;
$scrollbarThumbHover: #868686;
