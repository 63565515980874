eshop-menu-button {
  @import '../../../../../../../../../../../tools/themes/color-palette.scss';

  .icon-btn {
    .menu-btn-box {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 8px;
      align-items: center;
      align-content: center;

      height: 100%;
      width: 100%;

      .btn-text-box {
        color: $textColorBlack;
        font-weight: 400;
      }
    }
  }

  .button-menu {
    margin-top: 15px;
  }
}
