@import '../../../../../tools/themes/color-palette.scss';

.mobile-router-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;

  .mobile-router {
    width: 100%;
    height: fit-content;
    max-height: 100%;
    overflow: auto;
  }
}

.desktop-grouping-box {
  position: fixed;
  display: flex;
  flex-direction: row;
  background: $primaryColorGray;
  width: 100vw;

  gap: 40px;
  justify-content: center;

  .desktop-router-wrapper {
    // 360px je šířka košíku (320px + gap 40px) a 260px je padding vlevo a vpravo (2 x 130px)
    width: calc(100% - 360px - 260px);
    height: calc(100vh - 118px);
    max-width: 1400px;
    position: relative;

    @media screen and (max-width: 1280px) {
      // pod 1280px zmenšíme šedivé okraje na 80px (160px = 2 x 80px)
      width: calc(100% - 360px - 160px);
    }

    @media screen and (max-width: 1200px) {
      // pod 1200px zmenšíme šedivé okraje na 40px (80px = 2 x 40px)
      width: calc(100% - 360px - 80px);
    }

    .desktop-router {
      width: 100%;
      height: 100%;
      // globální nastavení stylování scrollbaru v destop komponentách pro firefox
      scrollbar-color: $scrollbarThumb $scrollbarTrack;
      background: $primaryColorWhite;
      overflow: auto;
    }
  }

  &.domovska_stranka {
    gap: 0; // gap mezi stránkou a košíkem

    .desktop-router-wrapper {
      width: calc(100vw - 260px);
      max-width: 2000px;

      @media screen and (max-width: 1280px) {
        // pod 1280px zmenšíme šedivé okraje na 80px (160px = 2 x 80px)
        width: calc(100vw - 160px);
      }

      @media screen and (max-width: 1200px) {
        // pod 1200px zmenšíme šedivé okraje na 40px (80px = 2 x 40px)
        width: calc(100vw - 80px);
      }
    }
  }

  &.objednavky {
    .desktop-router-wrapper {
      max-width: 480px;
    }
  }

  &.doruceni {
    .desktop-router-wrapper {
      max-width: 480px;
    }
  }

  // globální nastavení stylování scrollbaru v destop komponentách pro chrome
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: $scrollbarTrack;
  }
  ::-webkit-scrollbar-thumb {
    background: $scrollbarThumb;
    border-radius: 16px;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: $scrollbarThumbHover;
  }

  .desktop-shopping-cart {
    width: fit-content;
    height: fit-content;
  }
}
