@import '../../../../../../../../../tools/themes/color-palette.scss';

eshop-cookies-banner {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
  max-height: 100vh;
  overflow-y: auto;
  background: darkgray;
  display: flex;
  justify-content: center;
  color: $textColorBlack;

  // globální nastavení stylování scrollbaru v destop komponentách pro chrome
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: $scrollbarTrack;
  }
  &::-webkit-scrollbar-thumb {
    background: $scrollbarThumb;
    border-radius: 16px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $scrollbarThumbHover;
  }

  .banner-content {
    display: flex;
    width: 100%;
    gap: 16px;

    &.mobile {
      flex-direction: column;
      padding: 16px;

      .expanded-info {
        max-height: calc(100vh - 302px);
      }
    }

    &.desktop {
      flex-direction: row;
      justify-content: space-between;
      max-width: 900px;
      padding: 32px;

      .expanded-info {
        max-height: calc(100vh - 207px);
      }
    }

    .message-box {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .label {
        font-size: 16px;
        font-weight: 700;
      }

      .message {
        font-size: 13px;
      }

      .detailed-info {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .expand-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: fit-content;
          gap: 16px;
          font-size: 13px;
          cursor: pointer;
          padding: 8px 0;

          &:hover {
            .expand-info-msg {
              text-decoration: underline;
            }
          }
        }

        .expanded-info {
          overflow-y: auto;

          // globální nastavení stylování scrollbaru v destop komponentách pro chrome
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-track {
            background: $scrollbarTrack;
          }
          &::-webkit-scrollbar-thumb {
            background: $scrollbarThumb;
            border-radius: 16px;
            overflow: hidden;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: $scrollbarThumbHover;
          }

          .detailed-info-list {
            display: flex;
            flex-direction: column;
            gap: 16px;
            font-size: 11px;

            .label {
              font-size: 14px;
              font-weight: 700;
            }

            .cookie-type {
              display: flex;
              flex-direction: row;
              gap: 8px;

              .index {
                font-size: 12px;
                font-weight: 700;
              }

              .data {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .label {
                  font-size: 12px;
                  font-weight: 700;
                }

                .message {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }

    .buttons-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;

      .reject-box {
        width: 100%;
        text-align: center;

        .reject-msg {
          font-size: 13px;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      button {
        width: 100%;
      }
    }
  }
}
